
import * as ipxRuntime$C0MC4i3pay from '/home/e-store/node_menu/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as cacheIpxRuntime$9pJw760tQQ from '/home/e-store/node_menu/providers/cache_ipx.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "modified": {
      "modifiers": {
        "fit": "cover",
        "format": "webp",
        "quality": 80,
        "loading": "lazy"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "e-store.biz"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$C0MC4i3pay, defaults: {"maxAge":31536000} },
  ['cacheIpx']: { provider: cacheIpxRuntime$9pJw760tQQ, defaults: {"storageUrl":"https://local.e-store.biz/storage"} }
}
        